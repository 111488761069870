
// @ts-nocheck


export const localeCodes =  [
  "us",
  "in"
]

export const localeLoaders = {
  "us": [{ key: "../locales/en-US.js", load: () => import("../locales/en-US.js" /* webpackChunkName: "locale__vercel_path0_locales_en_US_js" */), cache: true }],
  "in": [{ key: "../locales/en-IN.js", load: () => import("../locales/en-IN.js" /* webpackChunkName: "locale__vercel_path0_locales_en_IN_js" */), cache: true }]
}

export const vueI18nConfigs = [
  
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "",
  "locales": [
    {
      "code": "us",
      "dir": "ltr",
      "language": "en-US",
      "files": [
        "/vercel/path0/locales/en-US.js"
      ]
    },
    {
      "code": "in",
      "dir": "ltr",
      "language": "en-IN",
      "files": [
        "/vercel/path0/locales/en-IN.js"
      ]
    }
  ],
  "defaultLocale": "us",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix_except_default",
  "lazy": true,
  "langDir": "locales",
  "detectBrowserLanguage": {
    "alwaysRedirect": false,
    "cookieCrossOrigin": false,
    "cookieDomain": null,
    "cookieKey": "i18n_redirected",
    "cookieSecure": false,
    "fallbackLocale": "",
    "redirectOn": "root",
    "useCookie": true
  },
  "differentDomains": false,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "config",
  "pages": {
    "fine-jewelry/hoop-earrings": {
      "in": "/fine-jewellery/hoop-earrings"
    },
    "fine-jewelry/tennis-necklaces": {
      "in": "/fine-jewellery/tennis-necklaces"
    },
    "fine-jewelry/initial-necklaces": {
      "in": "/fine-jewellery/initial-necklaces"
    },
    "fine-jewelry/fashion-necklaces": {
      "in": "/fine-jewellery/fashion-necklaces"
    },
    "fine-jewelry/solitaire-necklaces": {
      "in": "/fine-jewellery/solitaire-necklaces"
    },
    "high-jewelry/index": {
      "in": "/high-jewellery"
    },
    "high-jewelry/colored-diamond-rings": {
      "in": "/high-jewellery/colored-diamond-rings"
    },
    "high-jewelry/soiree-cocktail-jewelry": {
      "in": "/high-jewellery/soiree-cocktail-jewellery"
    },
    "high-jewelry/rose-portrait-jewelry": {
      "in": "/high-jewellery/rose-portrait-jewellery"
    },
    "high-jewelry/hues-rings": {
      "in": "/high-jewellery/hues-rings"
    },
    "fine-jewelry/index": {
      "in": "/fine-jewellery"
    },
    "fine-jewelry/stud-earrings": {
      "in": "/fine-jewellery/stud-earrings"
    },
    "fine-jewelry/tennis-bracelets": {
      "in": "/fine-jewellery/tennis-bracelets"
    },
    "fine-jewelry/bangle-bracelets": {
      "in": "/fine-jewellery/bangle-bracelets"
    },
    "fine-jewelry/hues-rings": {
      "in": "/fine-jewellery/hues-rings"
    },
    "fine-jewelry/soiree-rings": {
      "in": "/fine-jewellery/soiree-rings"
    },
    "fine-jewelry/fashion-bracelets": {
      "in": "/fine-jewellery/fashion-bracelets"
    },
    "fine-jewelry/fashion-earrings": {
      "in": "/fine-jewellery/fashion-earrings"
    },
    "diamond-jewelry": {
      "in": "/diamond-jewellery"
    },
    "diamond-jewelry/[[start_with]]/index": {
      "in": "/diamond-jewellery/[[start_with]]"
    },
    "customized-jewelry": {
      "in": "/customise-jewellery"
    }
  },
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "multiDomainLocales": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "code": "us",
    "dir": "ltr",
    "language": "en-US",
    "files": [
      {
        "path": "/vercel/path0/locales/en-US.js"
      }
    ]
  },
  {
    "code": "in",
    "dir": "ltr",
    "language": "en-IN",
    "files": [
      {
        "path": "/vercel/path0/locales/en-IN.js"
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
