<template>
    <div class="sub_menu_parent">
        <NuxtLink @click="$emit('hideShowMenu')" class="for_desktop_mod hover_cta" :to="localePath('/fine-jewelry')">{{ $t('jewelry') }} </NuxtLink>
        <div class="for_mobile_mod" @click="toggleMenu()">
            <div class="link_with_arrow">
                <span>{{ $t('jewelry') }}</span>
                <!-- <SvgHeaderSvg :type="'down'" /> -->
                <!-- <HeaderSvg v-if="arrow" :type="'down'" />
                <HeaderSvg v-else :type="'up'" /> -->
                <SvgHeaderSvg v-show="!isToggle" :type="'down'" />
                <SvgHeaderSvg v-show="isToggle" :type="'up'" />
            </div>
        </div>
        <div ref="main_menu_box" class="main_menu_box" :class="{ 'open_for_desktop' : hoverFlag === 'jewelry' ,'hide-menu': isHideMenu  }">
          <!-- ssr-only :hydratable="true" :hydrate-on-visible="true" -->
            <div class="inner_menu">
                <div class="landing_link inner_menu_box for_mobile_mod">
                    <NuxtLink @click="$emit('hideShowMenu')" :to="localePath('/fine-jewelry')">{{ $t('jewelry') }} </NuxtLink>
                </div>
                <div class="inner_menu_box inner_menu_three">
                    <div class="menu_card">
                        <h3>Rings</h3>
                        <div class="menu_ordered">
                            <NuxtLink @click="$emit('hideShowMenu')" :to="localePath({ name: 'shop-eternity'})"><SvgJewelrySvg :type="'eternity'" />&nbsp; Eternity Rings </NuxtLink>
                            <NuxtLink @click="$emit('hideShowMenu')" :to="localePath({ name:'toi-et-moi-ring'})">
                                <SvgJewelrySvg :type="'toi-moi'" />&nbsp;Toi Et Moi Rings
                            </NuxtLink>
                            <NuxtLink @click="$emit('hideShowMenu')" :to="localePath({ name: 'shop-anniversary',  query:{'Style': 'Five-stone', 'RefreshKey': 'Five-stone'}})"><SvgJewelrySvg :type="'five_stone'" />&nbsp; Five Stone Rings </NuxtLink>
                            <NuxtLink @click="$emit('hideShowMenu')" :to="localePath({ name: 'shop-anniversary',  query:{'Style': 'Seven-stone', 'RefreshKey': 'Seven-stone'}})"><SvgJewelrySvg  :type="'seven_stone'" />&nbsp; Seven Stone Rings </NuxtLink>
                            <NuxtLink @click="$emit('hideShowMenu')" :to="localePath({ name: 'shop-couple-rings', query:{ 'Style': 'couple-rings', 'RefreshKey': 'couple-rings' }})"><SvgJewelrySvg :type="'couple'" />&nbsp; Couple Rings</NuxtLink>
                        </div>
                    </div>
                    <div class="menu_card">
                        <h3>Bracelets </h3>
                        <div class="menu_ordered">
                            <NuxtLink @click="$emit('hideShowMenu')" :to="localePath({ name: 'shop-bracelet'})"><SvgJewelrySvg :type="'bracelet'" /> &nbsp;&nbsp;Tennis Bracelets</NuxtLink>
                            <NuxtLink @click="$emit('hideShowMenu')" :to="localePath({ name: 'shop-fashion-bracelet'})"><SvgJewelrySvg :type="'fashion-bracelet'" /> &nbsp;&nbsp;Fashion Bracelets</NuxtLink>
                            <NuxtLink @click="$emit('hideShowMenu')" :to="localePath({ name: 'shop-bangle-bracelet'})"><SvgJewelrySvg :type="'bangle_bracelet_brown'" /> &nbsp;&nbsp;Openable Bangle Bracelets</NuxtLink>
                        </div>
                    </div>
                </div>              
                <div class="inner_menu_box inner_menu_three">
                    <div class="menu_card">
                        <h3>Earrings </h3>
                        <div class="menu_ordered menu_ordered_noicon">
                            <NuxtLink @click="$emit('hideShowMenu')" :to="localePath({ name: 'shop-earring'})"><SvgJewelrySvg :type="'stud'" />&nbsp; Stud Earrings </NuxtLink>
                            <NuxtLink @click="$emit('hideShowMenu')" :to="localePath({ name: 'shop-hoop'})"><SvgJewelrySvg :type="'hoop'" />&nbsp; Hoop Earrings </NuxtLink>
                            <NuxtLink @click="$emit('hideShowMenu')" :to="localePath({ name: 'shop-fashion-earring'})"><SvgJewelrySvg :type="'fashion_earrings_brown'" />  &nbsp;&nbsp;Fashion Earrings</NuxtLink>
                        </div>
                    </div>
                    <div class="menu_card">
                        <h3>Pendants & Necklaces</h3>
                        <div class="menu_ordered">
                            <NuxtLink @click="$emit('hideShowMenu')" :to="localePath({ name: 'shop-tennis-necklace'})"><SvgJewelrySvg :type="'necklase'" />&nbsp; Tennis Necklaces</NuxtLink>
                            <NuxtLink @click="$emit('hideShowMenu')" :to="localePath({ name: 'shop-solitaire-necklace'})"><SvgJewelrySvg :type="'solitaire'" />&nbsp; Solitaire Necklaces</NuxtLink>
                            <NuxtLink @click="$emit('hideShowMenu')" :to="localePath({ name: 'shop-fashion-necklace'})"><SvgJewelrySvg :type="'fashion_necklace'" />&nbsp;  Fashion Necklaces</NuxtLink>
                            <NuxtLink @click="$emit('hideShowMenu')" :to="localePath({ name: 'shop-initial-necklace'})"><SvgJewelrySvg :type="'initial_necklace'" />&nbsp;  Initial Necklaces</NuxtLink>
                        </div>
                    </div>
                </div>            
                <div class="inner_menu_box inner_menu_three">
                    <div class="menu_card">
                        <h3>create your own</h3>
                        <div class="menu_ordered">
                            <NuxtLink @click="$emit('hideShowMenu')" :to="localePath({ name:'cyo-setting-list-ring'})"><SvgJewelrySvg :type="'cyor'" />&nbsp; Create your own ring  </NuxtLink>
                            <NuxtLink @click="$emit('hideShowMenu')" :to="localePath({ name:'cyo-setting-list-earring'})"><SvgJewelrySvg :type="'cyoe'" />&nbsp; Create your own earring  </NuxtLink>
                            <NuxtLink @click="$emit('hideShowMenu')" :to="localePath({ name:'cyo-setting-list-pendant'})"><SvgJewelrySvg :type="'cyop'" />&nbsp; Create your own pendant  </NuxtLink>
                        </div>
                    </div>
                    <div class="menu_card">
                        <!-- <h3>Blogs</h3>  -->
                        <h3 class="">bespoke fine {{ $t('jewelry') }}</h3>
                        <div class="menu_ordered menu_ordered_noicon">
                            <NuxtLink @click="$emit('hideShowMenu')" :to="localePath('/customized-jewelry')">{{ $t('customize') }} Your {{ $t('jewelry') }}</NuxtLink>
                        </div>
                    </div>
                    <div class="menu_card">
                        <h3 v-if="$country.is_india">High Jewellery</h3>
                        <h3 v-else>High Jewelry</h3>
                        <div class="menu_ordered">
                            <NuxtLink @click="$emit('hideShowMenu')" :to="localePath({ path: '/high-jewelry/hues-rings'})">Hues Colored Engagement Rings</NuxtLink>
                            <NuxtLink @click="$emit('hideShowMenu')" :to="localePath({ name: 'shop-soiree-cocktail-jewelry'})">Soirée Cocktail Collection </NuxtLink> 
                            <NuxtLink @click="$emit('hideShowMenu')" :to="localePath({ name: 'shop-rose-portrait-jewelry'})">Roses & Portraits Collection</NuxtLink> 
                        </div>
                    </div>
                </div>
                
                 <div class="inner_menu_box inner_menu_three">
                    <div class="menu_card">
                        <h3>Featured</h3>
                        <div v-if="!$country.is_india" class="menu_ordered">
                            <!-- <NuxtLink @click="$emit('hideShowMenu')" :to="localePath('/valentines-day-jewelry')">Shop Valentine's Day Jewelry</NuxtLink> -->
                            <NuxtLink @click="$emit('hideShowMenu')" :to="localePath({ name:'shop-all',  query:{'Shipdate': '8_days', 'RefreshKey': '8_days'} })">Shop In Stock Jewelry</NuxtLink> 
                            <NuxtLink @click="$emit('hideShowMenu')" :to="localePath({ name:'shop-all', query: { 'RefreshKey': 'all' } })">Shop All Diamond Jewelry</NuxtLink> 

                            <NuxtLink @click="$emit('hideShowMenu')" :to="localePath({ name:'shop-all', params:{ start_with: 'gifts-under-1000', } })">Shop Gifts Under {{ $formatPrice(1000) }}</NuxtLink>
                            <NuxtLink @click="$emit('hideShowMenu')" :to="localePath({ name:'shop-all', params:{ start_with: 'gifts-under-2000' } })">Shop Gifts Under {{ $formatPrice(2000) }}</NuxtLink>
                            <NuxtLink @click="$emit('hideShowMenu')" :to="localePath({ name:'shop-all', params:{ start_with: 'gifts-under-3000' } })">Shop Gifts Under {{ $formatPrice(3000) }}</NuxtLink>
                        </div>

                        <div v-else class="menu_ordered">
                            <!-- <NuxtLink @click="$emit('hideShowMenu')" :to="localePath({ name:'shop-all',  query:{'Shipdate': '3_days'} })">Shop In Stock Jewellery</NuxtLink>  -->
                            <NuxtLink @click="$emit('hideShowMenu')" :to="localePath({ name:'shop-all' })">Shop All Diamond Jewellery</NuxtLink> 
                            <NuxtLink @click="$emit('hideShowMenu')" :to="localePath({ name:'shop-all', params:{ start_with: 'gifts-under-50000' } })">Shop Gifts Under {{ $formatPrice(50000) }}</NuxtLink>
                            <NuxtLink @click="$emit('hideShowMenu')" :to="localePath({ name:'shop-all', params:{ start_with: 'gifts-under-80000' } })">Shop Gifts Under {{ $formatPrice(80000) }}</NuxtLink>
                            <NuxtLink @click="$emit('hideShowMenu')" :to="localePath({ name:'shop-all', params:{ start_with: 'gifts-under-100000' } })">Shop Gifts Under {{ $formatPrice(100000) }}</NuxtLink>
                       </div>
                    </div>

                    <div class="menu_card">
                            <h3>ready to ship</h3>
                            <div class="menu_ordered">
                                <NuxtLink @click="$emit('hideShowMenu')" :to="localePath({ name:'instock_ring', 'RefreshKey': 'all'})">In-Stock Rings</NuxtLink>
                                <NuxtLink @click="$emit('hideShowMenu')" :to="localePath({ name:'instock_pendant', 'RefreshKey': 'all'})">In-Stock Pendants</NuxtLink> 
                                <NuxtLink @click="$emit('hideShowMenu')" :to="localePath({ name:'instock_earring', 'RefreshKey': 'all'})">In-Stock Earrings</NuxtLink> 
                                <NuxtLink @click="$emit('hideShowMenu')" :to="localePath({ name:'shop-all', query:{ 'Shipdate': '8_days','Style': 'Tennis-bracelet,Fashion-bracelet', 'RefreshKey': 'all'} })">In-Stock Bracelets</NuxtLink> 
                                <NuxtLink @click="$emit('hideShowMenu')" :to="localePath({ name:'shop-all', query:{ 'Shipdate': '8_days','Style': 'Tennis-necklace,Solitaire-necklace,Fashion-necklace', 'refreshKeyTwo': 'all'} })">In-Stock Necklaces</NuxtLink> 
                            </div> 
                        </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    const localePath = useLocalePath()
    const { $country } = useNuxtApp()
    const props = defineProps([
      'openOverlay',
      'hoverFlag',
      'locale',
      'subMenuState',
      'menuName',
      'isHideMenu'
    ]);

    const hideMenu = ref(false);
    const openMainMenu = ref(false);
    const arrow = ref(true);
    const main_menu_box = ref(null)
    const toggleState = toRef(props.subMenuState)

    const isToggle = computed(()=>{
        return toggleState.value[props.menuName]
    })

    const route = useRoute();
    const emit = defineEmits(['hideMenuInDesktop','handleSubMenuState','hideShowMenu'])


    // watch(route, (to, from) => {
    //   hideMenu.value = true;
    //   setTimeout(() => {
    //     hideMenu.value = false;
    //   }, 500);
    //   emit('hideMenuInDesktop');
    // });

    watch(() => props.openOverlay, (newVal, oldVal) => {
      if (!oldVal) {
        // arrow.value = true;
        // openMainMenu.value = false;
        emit('handleSubMenuState','');

      }
    });

    watch(isToggle,(oldVal,newVal)=>{
        nextTick(()=>{
                main_menu_box.value.style.display  = isToggle.value ? 'block' : 'none' 
          })
    })

   
    
    const toggleMenu = () => {
        // arrow.value = !arrow.value;
        // openMainMenu.value = !openMainMenu.value;
        emit('handleSubMenuState','jewelry');
       
    };

</script>


<style scoped>
.landing_link .inner_menu_box{
    padding: 10px 0 !important;
} 
.main_menu_block ul li a {
    color: #303030;
    font-size: 16px;
    line-height: 1.25;
    text-transform: capitalize;
    font-weight: 500;

    display: inline-block;
}
.inner_menu_box .menu_card{
    border-top: none;
}

.menu_ordered{
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}
</style>

